import { Person } from './person';

export class Activity {
  id: number; // Prjacti.ACTNR
  phaseId: string; // Prjacti.FASENR
  projectId: string; // Prjacti.PROJNR
  projectDescription: string; // (Prjecten.PROMSCHRIJVING)
  location?: string; // (Prjecten.WERKPLAATS)
  executorId?: number; // (Prjecten.TPLEIDER)
  commercialLeaderId?: number; // (Prjecten.CPLEIDER)
  startDate: any; // Prjacti.STARTDATUM & Prjacti.STARTTIJD
  endDate: any; // Prjacti.EINDATUM & Prjacti.EINDTIJD
  name?: string; // Actitab.ACTOMS
  comment?: string; //Prjacti.ACTIEOMS
  editable?: boolean; //Prjecten.STATUS
  isPlanned?: number; // Prjplan.PLANVOLGNR
  plannedInPeople?: Person[];

  constructor(id: number, phaseId: string, projectId: string, startDate: any, endDate: any) {
    this.id = id;
    this.phaseId = phaseId;
    this.projectId = projectId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.editable = true;
  }
}
