import { Injectable } from '@angular/core';
import { Filesystem } from '@capacitor/filesystem';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor() {
  }


  public checkForFileSystemPermissions(): Promise<boolean> {
    return Filesystem.checkPermissions().then(permissions => permissions.publicStorage === 'granted');
  }

  public checkForLocationPermissions(): Promise<boolean> {
    return Geolocation.checkPermissions().then(permissions => permissions.location === 'granted');
  }

  public requestFileSystemPermissions(): Promise<string> {
    return Filesystem.requestPermissions().then(permissions => permissions.publicStorage);
  }

  public requestLocationPermissions(): Promise<string> {
    return Geolocation.requestPermissions().then(permissions => permissions.location);
  }
}
